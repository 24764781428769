.trends {
  & .dashboard-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .no-data {
    text-align: center;
    padding: 10px 0 0;
  }
}
