table,
th,
td {
  border: none;
}

select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px 15px 55px;
  border-radius: var(--component-radius);
  box-shadow: var(--input-shadow);
  border: 1px var(--lighter-grey) solid;
  background: var(--white);
  font-size: 18px;
}

.global-table-component {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: var(--component-radius-secondary);
  box-shadow: var(--component-shadow-secondary);
  overflow: hidden;
  border-collapse: collapse;
  border: 1px var(--light-grey) solid;

  @media only screen and (max-width: 867px) {
    width: 1000px;
  }

  & th,
  & td {
    background: var(--light-grey);
    padding: 10px 15px;
  }

  & th {
    border: 1px var(--accent-grey) solid;
    text-align: left;
  }

  & td {
    background: var(--white);
    border: 1px var(--light-grey) solid;
    transition: 400ms;
    color: var(--dark-grey);
    font-size: 14px;
    padding: 20px 15px;
    word-break: break-word;
    text-align: left;

    @media only screen and (max-width: 1206px) {
      font-size: 11px;
    }
  }

  & tr:hover td {
    background: var(--lighter-grey);
    color: #333;
    cursor: pointer;
  }
}

.pagination {
  display: block !important;
  text-align: left;

  & select {
    width: auto;
    padding: 7px 10px;
    font-size: 12px;
    display: inline-block;
  }

  & span {
    font-size: 12px;
    color: var(--dark-grey);
    padding: 0 15px;
  }

  & button {
    border: 1px var(--light-grey) solid;
    outline: none;
    background: var(--white);
    padding: 7px;
    border-radius: 5px;
    transition: 400ms;

    &:hover {
      background: var(--light-grey);
      cursor: pointer;
    }
  }
}

// .table-row {
//   position: relative;
//   width: 100%;

//   &.header {
//     border-top: 1px var(--accent-grey) solid;
//   }

//   &.clickable {
//     transition: 400ms;

//     &:hover {
//       cursor: pointer;
//       background: var(--lighter-grey);
//     }
//   }
// }

// .date-th,
// .headline-th,
// .url-th,
// .hit-th,
// .opentext-th,
// .firstname-th {
//   display: inline-block;
//   vertical-align: top;
//   box-sizing: border-box;
//   overflow: hidden;
//   padding: 10px 25px 10px 15px;
//   width: 22%;
//   font-size: 12px;
//   border-left: 1px var(--accent-grey) solid;
//   border-bottom: 1px var(--accent-grey) solid;
//   min-height: 50px;

//   @media only screen and (max-width: 1867px) {
//     min-height: 80px;
//   }

//   @media only screen and (max-width: 1367px) {
//     min-height: 120px;
//   }
// }

// .date-th,
// .firstname-th {
//   width: 12%;
//   font-size: 10px;
// }
// .firstname-th {
//   font-size: 12px;
// }

// .table-row.header .date-th,
// .table-row.header .headline-th,
// .table-row.header .url-th,
// .table-row.header .hit-th,
// .table-row.header .opentext-th {
//   background: var(--light-grey);
//   font-size: 14px;
//   min-height: 0;
// }

// .hit-th {
//   border-right: 1px var(--accent-grey) solid;
// }
