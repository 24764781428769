.form-btn {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 10px 0 25px;

  & span {
    padding: 0 25px;
    font-size: 11px;
    color: var(--dark-grey);
    transition: 400ms;

    &:hover {
      cursor: pointer;
      color: var(--green-t5);

      .warning & {
        color: var(--red-t5);
      }
    }

    .warning & {
      color: var(--red-t4);
    }
  }
}

.main-form-btn {
  position: relative;
  padding: 15px 50px;
  font-size: 12px;
  text-transform: uppercase;
  background: var(--green-t2);
  border-radius: var(--component-radius);
  color: var(--green-t5);
  box-shadow: var(--input-shadow);
  transition: 400ms;

  &:hover {
    cursor: pointer;
    color: var(--white);
    background: var(--green-t3);
  }

  .warning & {
    background: var(--red-t4);
    color: var(--white);

    &:hover {
      background: var(--red-t5);
    }
  }
}

.edit-btn {
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: var(--component-radius);
  box-shadow: var(--input-shadow);
  transition: 400ms;
  display: inline-block;
  text-align: center;
  padding: 10px 25px;
  box-sizing: border-box;
  background: var(--blue-t3);
  color: var(--white);
  cursor: pointer;

  &:hover {
    background: var(--blue-t5);
  }

  &.disabled {
    cursor: default;
    background: #6a87b3cf;
  }

  &.save {
    background: var(--green-t2);

    &:hover {
      background: var(--green-t3);
    }
  }

  &-cancel {
    padding: 0 25px;
    font-size: 11px;
    color: var(--dark-grey);
    transition: 400ms;

    &:hover {
      cursor: pointer;
      color: var(--green-t5);
    }
  }
}
