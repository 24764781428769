.login {
  &-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
    transition: 200ms;
    z-index: 2;

    &.logging-in {
      backdrop-filter: blur(10px);
    }

    & .ps__rail-y {
      opacity: 1;
    }

    & .ps__rail-y:hover > .ps__thumb-y,
    & .ps__rail-y:focus > .ps__thumb-y,
    & .ps__rail-y.ps--clicking .ps__thumb-y {
      width: 8px;
      background-color: #79747e;
    }

    & .ps .ps__rail-y:hover,
    & .ps .ps__rail-y:focus,
    & .ps .ps__rail-y.ps--clicking {
      background: transparent;
    }
  }

  &-view {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 2;
  }

  &-box {
    position: relative;
    vertical-align: middle;
    width: 500px;
    box-sizing: border-box;
    padding: 80px 70px;
    background: var(--white);
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow);

    @media only screen and (max-width: 867px) {
      width: 96%;
      padding: 25px 40px;
      margin-top: -125px;
    }

    &.warning {
      padding: 50px 70px 15px;
    }
  }

  &-logo {
    position: relative;
    width: 100px;
    margin: 10px auto 25px;

    & img {
      width: 100%;
    }
  }

  .logging-in &-fields {
    display: none;
  }
}

.blur-frame {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  transition: 400ms;
  z-index: 1;
}

.tagline {
  position: relative;
  font-size: 22px;
  font-weight: 100;
  margin: 0 auto 50px;
  color: var(--dark-grey);
}

.page-wrap {
  position: relative;
  width: 100%;
  min-height: 750px;
}

.input {
  position: relative;
  width: 100%;
  margin: 7px auto 12px;

  &-icon {
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    margin: -12px 0 0 15px;
    opacity: 0.5;
  }

  & input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 50px 15px 55px;
    border-radius: var(--component-radius);
    box-shadow: var(--input-shadow);
    border: 1px var(--lighter-grey) solid;
    background: var(--white);
    font-size: 18px;
    transition: all 0.3s ease;

    &:active,
    &:focus,
    &:focus-visible {
      border: 1px solid var(--dark-grey);
    }
  }
}

.eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  top: 50%;
  margin: -12px 15px 0 0;
  cursor: pointer;

  & img {
    width: 25px;
  }
}

.form-btn {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 10px 0 25px;

  & span {
    padding: 0 25px;
    font-size: 11px;
    color: var(--dark-grey);
    transition: 400ms;
    cursor: pointer;

    &:hover {
      color: var(--green-t5);
    }
  }
}

.main-form-btn {
  position: relative;
  padding: 15px 50px;
  font-size: 12px;
  text-transform: uppercase;
  background: var(--green-t2);
  border-radius: var(--component-radius);
  color: var(--green-t5);
  box-shadow: var(--input-shadow);
  transition: 400ms;
  cursor: pointer;
}
