.user-add-wrap {
  background: rgba(0, 0, 0, 0.36);
}

.add-user {
  &-box {
    width: 80vw;
    z-index: 9999;
  }

  &-left,
  &-right {
    width: 50%;
    vertical-align: top;
    padding: 0 15px;
    margin: 0;
    display: inline-block;
  }
}
