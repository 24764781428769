.tags {
  height: 450px;
  margin-bottom: 35px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px 3px #000 inset;
  -moz-box-shadow: 0 0 10px 3px #000 inset;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1) inset;
  padding: 15px 0;

  &.n0 {
    height: 80px;
  }

  &.n1 {
    height: 136px;
  }

  &.n2 {
    height: 227px;
  }

  &.n3 {
    height: 318px;
  }

  &.n4 {
    height: 409px;
  }
}

.checkarea {
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  box-shadow: var(--component-shadow);
  margin: 15px 7px;
  padding: 7px 15px;
  box-sizing: border-box;
  border: 1px transparent solid;

  & label {
    font-size: 12px;
    color: var(--dark-grey);
    padding-left: 7px;
  }

  &.checked {
    border: 1px var(--blue-t3) solid;
    width: calc(100% - 25px);
  }

  &.searcharea {
    box-sizing: border-box;
    width: calc(100% - 25px);
    text-align: left;
    display: block;
    margin: 15px auto;
    padding: 25px 15px;
    cursor: pointer;

    & input,
    & label {
      cursor: pointer;
    }
  }
}

.logo-large {
  font-size: 27px;
  font-weight: 300;
  padding: 0 0 25px;
}
